.dropbtn {
  padding: 16px;
  font-size: 16px;
  border: none; }

.dropdown {
  position: relative;
  display: inline-block; }
  @media (max-width: 1000px) {
    .dropdown .dropdown {
      color: white; }
      .dropdown .dropdown-content {
        background: none; }
        .dropdown .dropdown-content a {
          color: white; } }
  .dropdown a {
    cursor: pointer;
    white-space: nowrap; }
  .dropdown-2 {
    padding: 5px 20px; }
  .dropdown_section {
    margin-bottom: 10px; }
    .dropdown_section__title {
      display: flex;
      cursor: pointer;
      padding: 0; }
      .dropdown_section__title::before {
        content: "+";
        width: 30px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 500;
        border: 1px solid #242321;
        border-radius: 100%;
        transition: all 0.2s; }
    .dropdown_section-content {
      display: none; }
    .dropdown_section.active > .dropdown_section-content {
      display: block;
      margin-left: 55px; }
    .dropdown_section.active > .dropdown_section__title::before {
      content: "+";
      transform: rotate(45deg);
      width: 30px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: #ffc80d;
      font-size: 24px;
      font-weight: 500;
      border: 1px solid #ffc80d;
      border-radius: 100%;
      transition: all 0.2s; }

.dropdown-2 {
  margin-bottom: 0; }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 100;
  padding: 20px; }
  @media (max-width: 1000px) {
    .dropdown-content {
      position: static; } }

.dropdown-content a {
  color: #242321;
  text-decoration: none;
  display: block;
  white-space: nowrap; }
  .dropdown-content a:not(.dropdown_section__title) {
    margin-left: 10px;
    padding: 5px; }

/* .dropdown-content a:hover {background-color: #ddd;} */
.dropdown:hover .dropdown-content {
  display: block; }
