.body-active {
  overflow: hidden; }
  .body-active header {
    z-index: 3; }

header {
  position: fixed;
  width: 100%;
  z-index: 10; }
  header.header-mod {
    z-index: 2; }

.header {
  margin: 15px 20px 0; }

.opacitynull {
  opacity: 0; }

main {
  position: relative; }

.main_bg-black {
  background: #302d2d; }

.main_bg-grey {
  background-color: #f6f6f6; }
  .main_bg-grey .service__box_tab .tab__header_wrap .tab__header_item-active .price {
    background-color: #f6f6f6; }

.main_bg-grey2 {
  background-color: #ececec; }

.overflow {
  overflow: hidden; }

.container-row {
  display: flex;
  align-items: center; }

.container-coll {
  display: flex;
  flex-direction: column;
  align-items: baseline; }

.container-general {
  width: 85%;
  margin: 0 auto;
  max-width: 1440px; }

.container-coll-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  grid-column-gap: 68px;
  grid-row-gap: 40px;
  width: 100%;
  box-sizing: border-box; }

.container-coll-2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 40px; }

.container-coll-2_service {
  display: grid;
  grid-template-columns: minmax(300px, 0.8fr) minmax(300px, 1fr);
  grid-column-gap: 40px; }

.container-coll-3_bottom {
  display: grid;
  grid-template-columns: 1fr 1fr 180px;
  align-items: center;
  grid-gap: 40px; }

.container__header {
  margin-top: 110px;
  margin-bottom: 70px; }
  .container__header-service .container-title {
    margin-left: 100px; }
  .container__header-service .text {
    margin: 10px 0 20px; }
  .container__header-service .text,
  .container__header-service .btn {
    margin-left: 165px;
    display: inline-block; }
  .container__header-mr-l100 {
    margin-left: 100px; }
  .container__header-md {
    max-width: 550px; }
  .container__header-cent {
    max-width: 800px;
    margin: 0 auto; }
  .container__header .title {
    font-size: 48px;
    font-weight: bold;
    line-height: 1.35;
    color: #2f2d2d;
    margin-bottom: 5px; }
    .container__header .title-mr10 {
      margin-left: 20px; }
  .container__header .subtitle {
    font-size: 24px;
    line-height: 1.42;
    color: #2f2d2d; }
    .container__header .subtitle-bold {
      font-weight: bold; }

.mr-l-10 {
  margin-left: 10px; }

.mr-l-30 {
  margin-left: 30px; }

.mr-l-75 {
  margin-left: 75px; }

.mr-l-100 {
  margin-left: 100px; }

.mr-t-20 {
  margin-top: 20px; }

.mr-t-30 {
  margin-top: 30px; }

.mr-t-40 {
  margin-top: 40px; }

.mr-b-20 {
  margin-bottom: 20px; }

.mr-b-30 {
  margin-bottom: 30px; }

.mr-b-40 {
  margin-bottom: 40px; }

.mr-b-50 {
  margin-bottom: 50px; }

.mr-tb-20 {
  margin: 20px 0 !important; }

.mr-tb-40 {
  margin: 40px auto !important; }

.mr-tb-60 {
  margin: 60px 0 !important; }

.box-max800 {
  max-width: 800px; }

.box-max900 {
  max-width: 900px; }

.box-max920 {
  max-width: 920px; }

.box-max1100 {
  max-width: 1100px; }

.box-spaswbutven {
  justify-content: space-between; }

.box-margin_center {
  margin: 0 auto; }

.box-max-500 {
  max-width: 450px; }

.box-max-730 {
  max-width: 730px; }

.text {
  font-size: 21px;
  line-height: 1.62;
  color: #2f2d2c; }
  .text-bold {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5;
    color: #2f2d2d; }

.title-level-2, .title-level-3, .title-level-4, .title-level-5, .title-level-6 {
  font-weight: bold;
  color: #2f2d2c;
  line-height: 1;
  margin-bottom: 10px; }

.title-level-2 {
  font-size: 48px; }

.title-level-3 {
  font-size: 36px; }

.title-level-4 {
  font-size: 24px; }

.title-level-5 {
  font-size: 21px; }

.title-level-7 {
  font-size: 14px; }

.title-max-500 {
  max-width: 500px; }

.title-line-heig {
  line-height: 1.39; }

.portfolio_card__wrap {
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr)); }

.bg-grey {
  background-color: #f6f6f6; }

@media screen and (max-width: 768px) {
  .container-coll-3_bottom {
    grid-template-columns: 1fr; }
  .text {
    font-size: 18px; }
  .title-level-2 {
    font-size: 38px; }
  .title-level-3 {
    font-size: 30px;
    font-weight: bold;
    color: #242321;
    line-height: 1; }
  .title-level-4 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.2;
    color: #2f2d2d;
    line-height: 1;
    margin-bottom: 10px; } }

@media screen and (max-width: 488px) {
  .title-level-2 {
    font-size: 30px; }
  .title-level-3 {
    font-size: 26px; }
  .title-level-4 {
    font-size: 20px; } }

@media screen and (max-width: 420px) {
  .container-coll-card {
    grid-template-columns: repeat(auto-fit, minmax(265px, 1fr)); } }

.order_one_click_form {
  display: none; }

.get_consultation_form {
  display: none; }

.order_service_small_form {
  display: none; }
