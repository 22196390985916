.dropdown {
  color: white; }
  .dropdown-content {
    background: none; }
    .dropdown-content a {
      color: white; }

.svg_dot_letter {
  height: 40vw;
  width: 40vw; }

.mini_footer__wrap .footer_sections {
  display: flex;
  flex-direction: row-reverse !important; }

.mini_footer__wrap .main__foter_lang {
  margin-left: 30px; }

.mini_footer__wrap .footer_group {
  display: flex !important;
  align-items: center;
  margin-bottom: 0; }
  .mini_footer__wrap .footer_group a {
    margin: 0px 10px; }

.mini_footer__wrap .footer_main {
  margin-top: 0; }

.section {
  overflow: hidden;
  background-color: #fff; }

.box__main-work-btn {
  display: none; }

.box_static {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  width: 90%;
  grid-column-gap: 50px;
  align-items: center; }
  .box_static_text {
    font-size: 24px;
    line-height: 1.5;
    color: #302d2d; }
  .box_static_title {
    font-size: 60px;
    font-weight: 900;
    line-height: 1;
    color: #302d2d;
    margin: 10px 0 25px; }
  .box_static_subtitle {
    font-size: 24px;
    line-height: 1.4;
    font-weight: normal;
    color: #302d2d; }
  .box_static_btn {
    font-size: 16px;
    color: #302d2d;
    font-weight: bold;
    display: inline-block;
    align-items: center;
    margin: 20px 0;
    position: relative;
    padding: 20px;
    animation: animateThis 1s ease-in; }
    .box_static_btn_icon {
      width: 56px;
      height: 56px;
      background-color: #ffc80d;
      border-radius: 50%;
      position: absolute;
      z-index: -1;
      left: -5px;
      transform: translate(0%, -50%);
      top: 50%; }
    .box_static_btn a {
      text-transform: uppercase; }
    .box_static_btn svg {
      margin-left: 15px; }
  .box_static_drag {
    display: flex;
    align-items: center;
    max-width: 220px;
    justify-content: flex-end;
    margin: 0 0 0 auto;
    bottom: 100px;
    position: absolute;
    left: calc(100% - 37% - 380px); }

.box__shifting {
  position: absolute;
  height: 100%;
  width: 0%;
  background: #242321;
  display: flex;
  align-items: flex-end;
  min-width: 0;
  visibility: hidden; }
  .box__shifting_container {
    width: 100%;
    height: 100%;
    margin: 0 0 0 auto;
    position: relative;
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .box__shifting_container-active {
      opacity: 1;
      visibility: visible;
      transition: 0.3s; }
  .box__shifting_svg_bottom {
    position: absolute;
    bottom: 0;
    height: 60vw;
    width: 60vw;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 1; }
  .box__shifting-form {
    justify-content: center; }

.box__fix {
  position: absolute;
  top: 50%;
  left: 0;
  height: 100%; }
  .box__fix_text {
    color: rgba(36, 35, 33, 0.1);
    position: absolute;
    left: 0px;
    font-size: 19vh;
    font-weight: bold;
    top: 50%;
    line-height: 1;
    transform-origin: top left;
    white-space: nowrap; }
  .box__fix_img {
    position: absolute;
    top: 50%;
    height: 35vh; }
    .box__fix_img .img_dot {
      height: 80%;
      width: auto; }
    .box__fix_img picture {
      width: 100%;
      height: 100%;
      display: block;
      transition: 0.3s; }
    .box__fix_img img {
      height: 100%;
      width: auto; }
  .box__fix_main {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%); }
  .box__fix_dot {
    height: 100%;
    width: 100%;
    overflow: hidden;
    opacity: 1;
    display: flex;
    align-items: center;
    position: relative; }
    .box__fix_dot .img_dot {
      height: 80%;
      width: auto; }
      .box__fix_dot .img_dot img {
        height: 100%;
        width: auto;
        transform-origin: center;
        transform: translate(-50%, 0%);
        animation: dot_animation 75s ease infinite; }

.box_services .box__main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: calc(100% - 90px - 20vh);
  margin: 0 90px 0 auto;
  grid-gap: 20px; }
  .box_services .box__main_item {
    transform: scale(1);
    transition: 0.3s;
    cursor: pointer;
    margin-bottom: 15px; }
    .box_services .box__main_item-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 3;
      color: #fff; }
    .box_services .box__main_item-subtitle {
      font-size: 14px;
      line-height: 1.5;
      color: #ffffff; }
    .box_services .box__main_item-btn {
      font-size: 16px;
      font-weight: bold;
      color: #ffc80d;
      line-height: 1;
      opacity: 0;
      position: absolute;
      transform: translate(0px, -5px);
      transition: 0.3s; }
    .box_services .box__main_item:hover {
      transform: scale(1.05);
      transition: 0.3s; }
      .box_services .box__main_item:hover .box__main_item-btn {
        transition: 0.3s;
        opacity: 1;
        position: absolute;
        transform: translate(0px, 5px); }

.box_services .box__footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .box_services .box__footer_link {
    font-size: 16px;
    font-weight: bold;
    line-height: 3.28;
    color: #fcc700;
    margin: 0 auto;
    transition: 0.3s;
    opacity: 0; }
    .box_services .box__footer_link:hover {
      color: #fff;
      transition: 0.3s; }
  .box_services .box__footer_conteiner {
    width: 70%;
    background: #ffc80d;
    padding: 20px 40px;
    align-self: flex-end;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center; }
  .box_services .box__footer_content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .box_services .box__footer_content-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 2;
      color: #2b2a27; }

.box_team .box__main {
  display: flex;
  justify-content: flex-end;
  height: calc(100vh - 172px);
  align-items: end;
  overflow: auto;
  width: calc(100% - 90px - 20vh);
  margin: 0 90px 0 auto; }
  .box_team .box__main .box__team {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    justify-content: space-between; }
    .box_team .box__main .box__team_item {
      width: 26%; }
      .box_team .box__main .box__team_item:nth-child(3n + 1) {
        max-width: unset !important;
        margin: 0px 0% 0px 0; }
      .box_team .box__main .box__team_item:nth-child(3n-1) {
        max-width: unset !important;
        margin: 190px 0 20px 0; }
      .box_team .box__main .box__team_item:nth-child(3n + 3) {
        max-width: unset !important;
        margin: 100px 0 0px 0%; }
    .box_team .box__main .box__team_name {
      font-size: 18px;
      font-weight: bold;
      color: #fff; }
    .box_team .box__main .box__team_position {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      margin: 18px 0 5px;
      text-transform: uppercase; }
    .box_team .box__main .box__team_img {
      width: 100%; }
      .box_team .box__main .box__team_img img {
        width: 100%;
        height: auto; }

.box_work .box__main {
  display: flex;
  justify-content: flex-end;
  height: calc(100vh - 172px);
  align-items: center;
  width: calc(100% - 90px - 20vh);
  margin: 0px 90px 0px auto; }
  .box_work .box__main .box__main_slider {
    opacity: 0;
    transition: 0.3s; }
  .box_work .box__main .slick-initialized {
    opacity: 1;
    transition: 0.3s; }
    .box_work .box__main .slick-initialized .slick-track {
      margin: 0 -40px; }
  .box_work .box__main .slider_work {
    width: 100%; }
    .box_work .box__main .slider_work .slick-list .slick-track .slick-slide {
      max-width: 420px;
      padding: 40px; }
      .box_work .box__main .slider_work .slick-list .slick-track .slick-slide:nth-child(odd) {
        margin-top: 0 !important;
        transition: 1s; }
      .box_work .box__main .slider_work .slick-list .slick-track .slick-slide:nth-child(even) {
        margin-top: 120px !important;
        transition: 1s; }
    .box_work .box__main .slider_work .slick-list .slick-track-even .slick-slide {
      max-width: 420px;
      padding: 40px; }
      .box_work .box__main .slider_work .slick-list .slick-track-even .slick-slide:nth-child(even) {
        margin-top: 0 !important;
        transition: 1s; }
      .box_work .box__main .slider_work .slick-list .slick-track-even .slick-slide:nth-child(odd) {
        margin-top: 160px !important;
        transition: 1s; }
    .box_work .box__main .slider_work .box__main_item .box__main_subtitle {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      margin: 19px 0 5px; }
    .box_work .box__main .slider_work .box__main_item .box__main_title {
      font-size: 24px;
      font-weight: bold;
      color: #fff; }
  .box_work .box__main .box__main_img {
    height: 350px;
    margin-bottom: 10px; }
    .box_work .box__main .box__main_img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .box_work .box__main .box__main_subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: 2.31px;
    color: #ffffff; }
  .box_work .box__main .box__main_title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    color: #ffffff; }

.box_response .box__main {
  display: flex;
  align-items: center;
  width: calc(100% - 90px - 20vh);
  margin: 0px 90px 0 auto; }
  .box_response .box__main .box__response {
    width: 100%;
    height: 100%; }
    .box_response .box__main .box__response_title {
      font-size: 48px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 30px; }
      .box_response .box__main .box__response_title p {
        margin: 0; }
    .box_response .box__main .box__response_subtitle {
      font-size: 23px;
      font-weight: normal;
      color: #fff;
      max-width: 600px;
      margin: 18px 0; }
    .box_response .box__main .box__response_form {
      margin: 65px 0;
      margin-right: 10%; }
      .box_response .box__main .box__response_form form {
        z-index: 2; }

.box__main_services {
  height: 50px; }
  .box__main_services img {
    height: 100%;
    width: auto; }

.box__main .box__work {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 65px;
  overflow: auto;
  height: 100%; }
  .box__main .box__work .box__team_item:nth-child(even) {
    margin-top: 100px; }

.work__control {
  position: absolute;
  display: flex;
  align-items: center;
  top: -5px;
  transform: translateY(-50%);
  width: 150px;
  z-index: 10; }
  .work__control .slider__control {
    width: 100%;
    height: 35px;
    background-color: #ffc80d;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    max-width: 35px;
    margin: 0 15px;
    box-sizing: border-box;
    transform: scale(1);
    transition: 0.3s; }
    .work__control .slider__control:hover {
      transform: scale(1.1);
      transition: 0.3s; }
    .work__control .slider__control:first-child {
      margin-left: 0; }
    .work__control .slider__control:last-child {
      margin-right: 0; }

.box__work .box__main_item {
  padding: 0px 20px; }

.box__header {
  display: flex;
  align-items: center;
  width: calc(100% - 90px - 20vh + 75px);
  margin: 60px 90px 0px auto; }
  .box__header_text {
    margin: 0 0 0 20px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    max-width: 464px; }

.box__main-form {
  z-index: 2; }

@keyframes bounceIn {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes bounceOn {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(-360deg); } }

@media screen and (max-width: 1630px) {
  #section_05 .box_response .box__main .box__response_title {
    font-size: 43px;
    margin-bottom: 10px; }
  #section_05 .box_response .box__main .box__response_subtitle {
    font-size: 20px;
    margin: 8px 0; }
  #section_05 .form_btn {
    margin-top: 0; }
  #section_05 .box_response .box__main .box__response_form {
    margin: 25px 0;
    margin-bottom: 45px; }
  .box_work .box__main .box__main_img {
    height: 270px;
    margin-bottom: 10px; }
  .box_response .box__main .box__response_form form {
    grid-template-columns: 1fr 1fr;
    margin-right: 5%; } }

@media screen and (max-width: 1400px) {
  .header__menu_item {
    margin-left: 15px;
    font-size: 16px; }
  .box_work .box__main .box__main_img {
    height: 230px;
    margin-bottom: 10px; } }

@media screen and (max-width: 1200px) {
  .footer_box__shifting_container .form_btn {
    margin-top: 0; }
  .footer_box__shifting_container .box__response_form {
    margin: 25px 0 !important; }
  .box_work .box__main .box__main_img {
    height: 200px;
    margin-bottom: 10px; }
  .box_team .box__main {
    width: calc(100% - 90px - 14vh); }
    .box_team .box__main .box__team_item {
      width: 26%; }
      .box_team .box__main .box__team_item:nth-child(3n + 1) {
        max-width: unset !important;
        margin: 0px 0% 0px 0; }
      .box_team .box__main .box__team_item:nth-child(3n-1) {
        max-width: unset !important;
        margin: 190px 0 20px 0; }
      .box_team .box__main .box__team_item:nth-child(3n + 3) {
        max-width: unset !important;
        margin: 100px 0 0px 0%; }
  .box_static_subtitle {
    font-size: 22px; }
  .box_static_title {
    font-size: 46px; }
  .box_static_text {
    font-size: 20px; } }

@media screen and (max-width: 1139px) {
  .box_response .box__main .box__response_form {
    margin: 5px 0;
    margin-right: 10%; } }

@media screen and (max-width: 1150px) {
  .header__menu_item {
    margin-left: 10px;
    font-size: 14px; }
  .box_services .box__footer_content-title {
    font-size: 18px; } }

.main_footer__wrap {
  display: none; }

@media screen and (max-width: 992px) {
  .mini_footer__wrap {
    display: none !important; }
  .main_footer__wrap {
    display: block !important; }
  .box_services .box__footer_link {
    display: none; }
  .box_services .box__footer_conteiner {
    width: 100%;
    align-items: center; }
  .box_team .box__main .box__team_img {
    width: 100%; }
    .box_team .box__main .box__team_img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .box__main-work-btn {
    display: block; }
  .box__main-work {
    flex-direction: column; }
  .box__main .box__work {
    overflow: visible; }
  .box__main .box__work .box__team_item:nth-child(even) {
    margin-top: 40px; }
  .box__main .box__work .box__team_item:nth-child(3) {
    margin-top: -30px; }
  .box__main .box__work {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); }
  .box_work .box__main .box__main_img {
    height: 250px;
    margin-bottom: 10px; }
  .box__shifting .box_services .box__main,
  .box__shifting .box_team .box__main,
  .box__shifting .box_work .box__main {
    opacity: 1; }
  .box__shifting .box_services,
  .box__shifting .box_team,
  .box__shifting .box_work {
    visibility: visible; }
  #section_05 .box_static_subtitle {
    display: none; }
  #section_05 .main_sections_btn {
    display: none !important; }
  .box__main-work {
    position: relative; }
  .box__header {
    display: none; }
  .box_services .box__main {
    width: 90%;
    margin: 20vh auto 10px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-content: space-between;
    text-align: center; }
    .box_services .box__main_item {
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .box_services .box__main_item-btn {
        bottom: -15px; }
  .box_services .box__footer {
    position: relative;
    width: 100%;
    transform: translateY(0px) !important;
    opacity: 1 !important; }
    .box_services .box__footer_content {
      justify-content: center;
      flex-direction: column; }
      .box_services .box__footer_content-title {
        margin: 0px 0 10px;
        font-size: 24px;
        font-weight: bold; }
      .box_services .box__footer_content .btn {
        border-radius: 0; }
  .box_team .box__main {
    width: 100%;
    margin: 10vh auto 10px;
    height: 100%; }
    .box_team .box__main .box__team {
      margin: 17vh 0 30px; }
      .box_team .box__main .box__team .slick-slide {
        padding: 0 15px; }
      .box_team .box__main .box__team_item {
        max-width: 100% !important; }
  .box_static {
    opacity: 1;
    grid-template-columns: 1fr;
    margin: 0 auto;
    margin-top: 50px; }
    .box_static_drag {
      display: none; }
  .box__shifting {
    min-width: 100%;
    position: relative;
    margin-top: 20vh;
    visibility: visible; }
    .box__shifting_svg_bottom {
      display: none; }
    .box__shifting_container {
      display: block;
      height: auto;
      opacity: 1;
      visibility: visible; }
    .box__shifting .box_services {
      visibility: visible; }
  .box__fix {
    top: 0%;
    height: 0%;
    width: 100%; }
    .box__fix_img {
      top: 0%;
      left: 50%;
      opacity: 1; }
    .box__fix_text {
      font-size: 18vh;
      left: 50%;
      top: 0%;
      transform: rotate(0) translate(-50%, -80%); }
  .box_work .box__main {
    height: auto;
    width: 90%;
    margin: 18vh auto 10px;
    margin-bottom: 40px; }
  .box_response .box__main {
    width: 90%;
    margin: 20vh auto 10px; }
    .box_response .box__main .box__response_form {
      margin: 65px 0; }
  .section .main__scrol {
    visibility: hidden; } }

@media screen and (max-width: 768px) {
  .box_services .box__footer_conteiner {
    flex-direction: column; }
  .box__work .box__main_img {
    height: 200px; } }

@media screen and (max-width: 600px) {
  .box__work .box__main_img {
    height: 150px; }
  .box_services .box__footer {
    padding-top: 20px; }
  .box_response .box__main .box__response_title {
    font-size: 36px; }
  .box_response .box__main .box__response_form form {
    grid-template-columns: 1fr;
    grid-gap: 20px; }
    .box_response .box__main .box__response_form form .form__sections {
      margin: 30px 0; }
      .box_response .box__main .box__response_form form .form__sections:first-child {
        margin: 0; } }

@media screen and (max-width: 768px) {
  .box__main .box__work {
    grid-template-columns: 1fr;
    grid-gap: 35px; }
  .box__main .box__work .box__team_item {
    margin-top: 0 !important; }
  .box_work .box__main .slider_work .slick-list .slick-track .slick-slide:nth-child(even) {
    margin-top: 0px !important;
    padding: 0 40px !important; }
  .box_work .box__main .slider_work .slick-list .slick-track .slick-slide:nth-child(odd) {
    margin-top: 0px !important;
    padding: 0 40px; }
  .work__control {
    width: auto; }
  .box__main-work {
    padding-top: 30px;
    height: 100% !important;
    margin: 21vh auto 30px !important; } }

@media screen and (max-width: 480px) {
  .box_team .box__main .box__team_img {
    width: 100%; }
    .box_team .box__main .box__team_img img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

@media screen and (max-width: 460px) {
  .box_work .box__main .box__main_img {
    height: 250px; }
  .box_services .box__footer_conteiner {
    padding: 20px 5px; } }

@keyframes dot_animation {
  0% {
    transform: translateX(-30px) rotate(0deg); }
  100% {
    transform: translateX(-30px) rotate(360deg); } }

@media screen and (max-width: 405px) {
  .box__work .box__main_img {
    height: 150px !important; } }
