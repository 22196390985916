.conteiner .title {
  font-size: 36px;
  font-weight: bold;
  color: #302d2d;
  margin-top: 30px; }

.conteiner .our_works_wrap {
  display: flex; }

.conteiner .our_works__item {
  width: 90% !important;
  outline: none; }
  .conteiner .our_works__item:hover img {
    transform: scale(1.15);
    transition: .3s; }
  .conteiner .our_works__item:last-child {
    margin-top: 40px; }
  .conteiner .our_works__item_img {
    height: 400px;
    margin: 25px 0;
    overflow: hidden; }
    .conteiner .our_works__item_img img {
      transition: .3s;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .conteiner .our_works__item_subtitle {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2.31px;
    color: #242321;
    text-transform: uppercase; }
  .conteiner .our_works__item_title {
    font-size: 24px;
    font-weight: bold;
    margin: 5px 0;
    transition: .3; }
    .conteiner .our_works__item_title:hover {
      opacity: .8;
      transition: .3; }

.conteiner .our_works_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 45px 0; }

@media screen and (max-width: 992px) {
  .conteiner .title {
    font-size: 36px;
    font-weight: bold;
    color: #302d2d;
    margin-top: 30px; }
  .conteiner .our_works__item_img {
    height: 200px; } }

.title__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-top: 8px; }
  .title__wrap .title {
    margin-top: 0 !important; }

.slick-wrap {
  display: flex;
  align-items: center;
  width: 70px;
  justify-content: space-between;
  margin-right: 60px; }

.all_class_slick {
  cursor: pointer; }

.slick-second .slick_btn_arrow {
  transform: rotate(180deg); }

@media screen and (max-width: 1200px) {
  .conteiner .our_works__item_img {
    height: 300px; } }

@media screen and (max-width: 775px) {
  .conteiner .our_works__item {
    width: 100% !important; } }

@media screen and (max-width: 680PX) {
  .slick-wrap {
    margin-right: 0; }
  .conteiner .our_works__item_img {
    height: 45vw; } }
