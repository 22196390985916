.section_bg {
  position: absolute;
  height: 0vh;
  width: 100%;
  background: #302d2d;
  z-index: 10;
  bottom: 0;
  transition: all 1s cubic-bezier(0.64, 0.27, 0.31, 1) 0ms; }
  .section_bg-active {
    transition: all 1s cubic-bezier(0.64, 0.27, 0.31, 1) 0ms;
    height: 100vh; }

.scroll_section {
  transition: all 400ms cubic-bezier(0.64, 0.27, 0.31, 1) 1000ms !important; }

.scroll_section_2 {
  transition: all 400ms cubic-bezier(0.64, 0.27, 0.31, 1) 1000ms !important; }

#section_02.animation .box__shifting {
  width: 0; }

#section_02.animation .box_static {
  opacity: 0; }

#section_02.animation .box__fix_text {
  transform: rotate(90deg) translate(50%, 15%) scale(-1); }

#section_02.animation .box__fix_dot svg {
  transform: translateX(-76%); }

#section_02.animation .box__fix_img {
  opacity: 0; }

.box_static {
  opacity: 0; }
  .box_static_drag {
    opacity: 0; }

.box__fix {
  pointer-events: none;
  transform: translate(0%, -50%); }
  .box__fix_text {
    pointer-events: none; }
  .box__fix_dot {
    pointer-events: none;
    transition: .3s; }
  .box__fix_img {
    pointer-events: none;
    transform: translate(-50%, -50%);
    opacity: 0; }

.box__shifting .box_services, .box__shifting .box_team, .box__shifting .box_work {
  visibility: hidden; }
  .box__shifting .box_services .box__header, .box__shifting .box_team .box__header, .box__shifting .box_work .box__header {
    transform: translateX(50px);
    opacity: 0; }
  .box__shifting .box_services .box__main, .box__shifting .box_team .box__main, .box__shifting .box_work .box__main {
    opacity: 0; }
  .box__shifting .box_services .box__footer, .box__shifting .box_team .box__footer, .box__shifting .box_work .box__footer {
    transform: translateY(50px);
    opacity: 0; }

.box__shifting_container {
  opacity: 0; }

.box-visible .box_static {
  animation: box_static-visible 1s ease-out 1.6s forwards; }
  .box-visible .box_static_drag {
    animation: box_static-visible 1s ease-out 1.6s forwards; }

.box-visible .box__fix_img {
  animation: box__fix_img-visible 0.5s cubic-bezier(0.42, 0, 0.47, 0.77) 1.7s forwards; }

.box-visible .box__fix_text {
  animation: box__fix_text-visible 0.4s cubic-bezier(0.42, 0, 0.47, 0.77) 1.9s forwards; }

.box-visible .box__fix_dot .img_dot {
  animation: box__dot_text-visible 1.2s cubic-bezier(0.64, 0.27, 0.31, 1) 1.3s forwards; }

.box-visible .box__shifting {
  animation: width_shifting-visible 1.1s cubic-bezier(0.64, 0, 0.3, 1) 1.2s forwards; }

.box-visible .main__scrol {
  animation: main_scrol-visible 1s ease-out 1.4s forwards; }

.box-hidden .box_static {
  animation: box_static-hidden .3s ease-out 0s forwards; }
  .box-hidden .box_static_drag {
    animation: box_static-hidden .3s ease-out 0s forwards; }

.box-hidden .box__fix_img {
  animation: box__fix_img-hidden 0.3s cubic-bezier(0.47, 0.77, 0.42, 0) 0s forwards; }

.box-hidden .box__fix_text {
  animation: box__fix_text-hidden 0.3s cubic-bezier(0.42, 0, 0.47, 0.77) 0s forwards; }

.box-hidden .box__fix_dot .img_dot {
  animation: box__dot_text-hidden 0.6s cubic-bezier(0.64, 0.27, 0.31, 1) 0s forwards; }

.box-hidden .box__shifting {
  animation: width_shifting-hidden .5s ease-in  forwards; }

.box-hidden .main__scrol {
  animation: main_scrol-hidden .5s ease-out 0s forwards; }

.box-open .box_static {
  animation: box_static-hidden .3s ease-out 0s forwards; }
  .box-open .box_static_drag {
    animation: box_static-hidden .3s ease-out 0s forwards; }

.box-open .box__fix_img {
  animation: box__fix_img-open 0.3s cubic-bezier(0.47, 0.77, 0.42, 0) 0s forwards; }

.box-open .box__fix_text {
  animation: box__fix_text-open 0.3s cubic-bezier(0.42, 0, 0.47, 0.77) 0s forwards; }

.box-open .box__fix_dot .img_dot {
  animation: box__dot_text-open 0.6s cubic-bezier(0.64, 0.27, 0.31, 1) 0s forwards; }

.box-open .box__shifting {
  animation: width_shifting-open .5s ease-in  forwards; }
  .box-open .box__shifting_container {
    opacity: 1; }
  .box-open .box__shifting .box_services, .box-open .box__shifting .box_team, .box-open .box__shifting .box_work, .box-open .box__shifting .box_response {
    visibility: visible; }
    .box-open .box__shifting .box_services .box__header, .box-open .box__shifting .box_team .box__header, .box-open .box__shifting .box_work .box__header, .box-open .box__shifting .box_response .box__header {
      animation: box__header_visible 0.6s ease-out 0.9s forwards; }
    .box-open .box__shifting .box_services .box__main, .box-open .box__shifting .box_team .box__main, .box-open .box__shifting .box_work .box__main, .box-open .box__shifting .box_response .box__main {
      animation: box__main_visible 1.2s ease-out 0.7s forwards; }
    .box-open .box__shifting .box_services .box__footer, .box-open .box__shifting .box_team .box__footer, .box-open .box__shifting .box_work .box__footer, .box-open .box__shifting .box_response .box__footer {
      animation: 600ms ease-out 900ms normal forwards running box__footer-visible; }
      .box-open .box__shifting .box_services .box__footer .box__footer_link, .box-open .box__shifting .box_team .box__footer .box__footer_link, .box-open .box__shifting .box_work .box__footer .box__footer_link, .box-open .box__shifting .box_response .box__footer .box__footer_link {
        opacity: 1;
        transition: all 400ms ease 2000ms; }

.box-open .main__scrol {
  animation: main_scrol-active .5s ease-out 0s forwards; }

.box-open_respons .box_static {
  animation: box_static-close-hidden .3s ease-out 0s forwards; }
  .box-open_respons .box_static_drag {
    animation: box_static-close-hidden .3s ease-out 0s forwards; }

.box-open_respons .box__fix_img {
  animation: box__fix_img-open 0.3s cubic-bezier(0.47, 0.77, 0.42, 0) 1.1s forwards; }

.box-open_respons .box__fix_text {
  animation: box__fix_text-open 0.3s cubic-bezier(0.42, 0, 0.47, 0.77) 1.1s forwards; }

.box-open_respons .box__fix_dot .img_dot {
  animation: box__dot_text-open 0.6s cubic-bezier(0.64, 0.27, 0.31, 1) 0.5s forwards; }

.box-open_respons .box__shifting {
  animation: width_shifting-open .5s ease-in 1.3s forwards; }
  .box-open_respons .box__shifting_container {
    animation: main_scrol-visible 0.6s ease-out   forwards; }
  .box-open_respons .box__shifting .box__header {
    animation: box__header_visible 0.6s ease-out 1.5s forwards; }
  .box-open_respons .box__shifting .box__main {
    opacity: 0;
    visibility: hidden;
    animation: box__main_visible 1.2s ease-out 1.9s forwards; }
  .box-open_respons .box__shifting .box__footer {
    animation: box__footer-visible 600ms ease-out 1.5ms normal forwards running; }

.box-open_respons .main__scrol {
  animation: main_scrol-active .5s ease-out 0.7s forwards; }

.box-close .box_static {
  animation: box_static-visible .3s ease-out .4s forwards; }
  .box-close .box_static_drag {
    animation: box_static-visible .3s ease-out .4s forwards; }

.box-close .box__fix_img {
  animation: box__fix_img-close 0.3s cubic-bezier(0.47, 0.77, 0.42, 0) 0s forwards; }

.box-close .box__fix_text {
  animation: box__fix_text-close 0.3s cubic-bezier(0.42, 0, 0.47, 0.77) 0s forwards; }

.box-close .box__fix_dot .img_dot {
  animation: box__dot_text-close 0.6s cubic-bezier(0.64, 0.27, 0.31, 1) 0s forwards; }

.box-close .box__shifting {
  animation: width_shifting-close .5s ease-in  forwards; }
  .box-close .box__shifting_container {
    opacity: 1; }
  .box-close .box__shifting .box_services {
    visibility: hidden; }
    .box-close .box__shifting .box_services .box__header {
      animation: box__header-not_visible 0.6s ease-out 0.9s forwards; }
    .box-close .box__shifting .box_services .box__main {
      animation: box__main-not_visible 1.2s ease-out  forwards; }
    .box-close .box__shifting .box_services .box__footer {
      animation: 600ms ease-out 900ms normal forwards running box__footer-not_visible; }

.box-close .main__scrol {
  animation: main_scrol-visible .5s ease-out 0s forwards; }

.box-close-hidden .box_static {
  animation: box_static-close-hidden .3s ease-out 0s forwards; }
  .box-close-hidden .box_static_drag {
    animation: box_static-close-hidden .3s ease-out 0s forwards; }

.box-close-hidden .box__fix_img {
  animation: box__fix_img-close-hidden 0.3s cubic-bezier(0.47, 0.77, 0.42, 0) 0s forwards; }

.box-close-hidden .box__fix_text {
  animation: box__fix_text-close-hidden 0.3s cubic-bezier(0.42, 0, 0.47, 0.77) 0s forwards; }

.box-close-hidden .box__fix_dot .img_dot {
  animation: box__dot_text-close-hidden 0.6s cubic-bezier(0.64, 0.27, 0.31, 1) 0s forwards; }

.box-close-hidden .box__shifting {
  animation: width_shifting-close-hidden .5s ease-in  forwards; }
  .box-close-hidden .box__shifting_container {
    animation: main_scrol-hidden 0.3s ease-out   forwards; }

.box-close-hidden .main__scrol {
  animation: main_scrol-hidden .5s ease-out 0s forwards; }

@keyframes main_scrol-visible {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes main_scrol-hidden {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes box_static-visible {
  0% {
    opacity: 0;
    transform: translateY(-30px); }
  50% {
    opacity: 0.4;
    transform: translateY(0px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes box_static-hidden {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(-30px); } }

@keyframes box_static-close-hidden {
  0% {
    opacity: .2;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(-30px); } }

@keyframes width_shifting-visible {
  0% {
    visibility: hidden; }
  100% {
    visibility: visible;
    width: 40%;
    min-width: 390px; } }

@keyframes width_shifting-hidden {
  0% {
    width: 40%;
    min-width: 390px;
    visibility: visible; }
  100% {
    width: 0%;
    min-width: 0px;
    visibility: hidden; } }

@keyframes width_shifting-close-hidden {
  0% {
    width: 88%;
    min-width: 390px;
    visibility: visible; }
  100% {
    width: 0%;
    min-width: 0px;
    visibility: hidden; } }

@keyframes width_shifting-open {
  0% {
    width: 40%;
    min-width: 0px;
    visibility: hidden; }
  100% {
    width: 88%;
    visibility: visible; } }

@keyframes box__fix_img-visible {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes box__fix_img-hidden {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes box__fix_img-close-hidden {
  0% {
    opacity: 1;
    transform: translate(-60%, -50%); }
  100% {
    opacity: 0; } }

@keyframes box__fix_text-visible {
  0% {
    transform: rotate(90deg) translate(50%, 21%) scale(-1); }
  100% {
    transform: rotate(90deg) translate(50%, 71%) scale(-1); } }

@keyframes box__fix_text-hidden {
  0% {
    transform: rotate(90deg) translate(50%, 71%) scale(-1); }
  100% {
    transform: rotate(90deg) translate(50%, 21%) scale(-1); } }

@keyframes box__fix_text-close-hidden {
  0% {
    transform: rotate(90deg) translate(50%, 71%) scale(-1); }
  100% {
    transform: rotate(90deg) translate(50%, 21%) scale(-1); } }

@keyframes box__dot_text-visible {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(-50%); } }

@keyframes box__dot_text-hidden {
  0% {
    transform: translateX(-50%); }
  100% {
    transform: translateX(-100%); } }

@keyframes box__dot_text-close-hidden {
  0% {
    transform: translateX(-50%);
    opacity: 0; }
  100% {
    transform: translateX(-100%); } }

@keyframes box_static-open {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: .2;
    transform: translateY(0px); } }

@keyframes box_static-close {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: .2;
    transform: translateY(0px); } }

@keyframes width_shifting-close {
  0% {
    width: 88%;
    visibility: visible; }
  100% {
    width: 40%;
    visibility: visible; } }

@keyframes box__fix_img-open {
  0% {
    opacity: 1; }
  40% {
    transform: translate(-10%, -50%); }
  100% {
    opacity: 1;
    transform: translate(-60%, -50%); } }

@keyframes box__fix_img-close {
  0% {
    opacity: 1; }
  60% {
    opacity: 1; }
  100% {
    opacity: 1;
    transform: translate(-60%, -50%); } }

@keyframes box__fix_text-open {
  0% {
    transform: rotate(90deg) translate(50%, 71%) scale(-1); }
  20% {
    transform: rotate(90deg) translate(50%, 11%) scale(-1); }
  85% {
    transform: rotate(90deg) translate(50%, 11%) scale(-1); }
  100% {
    transform: rotate(90deg) translate(50%, 71%) scale(-1); } }

@keyframes box__fix_text-close {
  0% {
    transform: rotate(90deg) translate(50%, 71%) scale(-1); }
  10% {
    transform: rotate(90deg) translate(50%, 121%) scale(-1); }
  60% {
    transform: rotate(90deg) translate(50%, 111%) scale(-1); }
  85% {
    transform: rotate(90deg) translate(50%, 31%) scale(-1); }
  100% {
    transform: rotate(90deg) translate(50%, 71%) scale(-1); } }

@keyframes box__dot_text-open {
  0% {
    transform: translateX(-50%);
    opacity: 1; }
  100% {
    transform: translateX(-50%);
    opacity: 0; } }

@keyframes box__dot_text-close {
  0% {
    transform: translateX(-50%);
    opacity: 0; }
  100% {
    transform: translateX(-50%);
    opacity: 1; } }

@keyframes box__header_visible {
  0% {
    transform: translateX(50px);
    opacity: 0; }
  100% {
    transform: translateX(0px);
    opacity: 1; } }

@keyframes box__header-not_visible {
  0% {
    transform: translateX(0px);
    opacity: 0; }
  100% {
    transform: translateX(50px);
    opacity: 1; } }

@keyframes box__main_visible {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes box__main-not_visible {
  0% {
    opacity: 1;
    visibility: visible; }
  5% {
    opacity: 0; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes box__footer-visible {
  0% {
    transform: translateY(50px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes box__footer-not-visible {
  0% {
    transform: translateY(0px);
    opacity: 0; }
  100% {
    transform: translateY(50);
    opacity: 1; } }
