.input {
  width: 100%;
  position: relative;
  margin: 30px 0; }
  .input_box {
    position: relative; }
  .input_label {
    position: absolute;
    top: 12px;
    left: 0;
    transition: .3s;
    font-size: 16px;
    line-height: 1; }
  .input-field {
    width: 100%;
    background: inherit;
    outline: none;
    border: none;
    font-size: 18px;
    line-height: 1;
    padding: 13px 0; }
  .input-black .input_label {
    color: #242321 !important; }
  .input-black .input-field {
    color: #242321 !important;
    border-bottom: 1px solid #242321 !important; }
    .input-black .input-field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px rgba(255, 255, 255, 0) inset;
      -webkit-text-fill-color: #242321 !important; }
    .input-black .input-field:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px rgba(255, 255, 255, 0) inset;
      -webkit-text-fill-color: #242321 !important; }
  .input-white .input_label {
    color: #fff !important; }
  .input-white .input-field {
    color: #fff !important;
    border-bottom: 1px solid #fff !important; }
    .input-white .input-field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px rgba(255, 255, 255, 0) inset;
      -webkit-text-fill-color: #fff !important; }
    .input-white .input-field:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px rgba(255, 255, 255, 0) inset;
      -webkit-text-fill-color: #fff !important; }

.in-focus .input_label {
  top: -10px;
  transition: .3s;
  font-size: 14px; }

.is-error.in-focus .input_label {
  color: #ff4b4b; }

.is-error .input-field {
  border-bottom: 1px solid #ff4b4b;
  color: #ff4b4b; }

.is-error .input_label {
  color: #ff4b4b; }

.is-error label.error {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #ff4b4b; }

label.error {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #ff4b4b; }
