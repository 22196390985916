.validation_container {
  position: relative; }

.validation_error {
  position: absolute;
  bottom: -26px;
  left: 0px;
  width: 100%;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  font-size: 16px;
  color: #dc2a1c; }
