.text_color_black {
  color: #302d2d !important; }

.btn:not(.main__foter_lang-link-active):hover {
  cursor: pointer !important; }
  .btn:not(.main__foter_lang-link-active):hover * {
    cursor: pointer !important; }

.btn-black {
  background: #2b2a27;
  padding: 15px 30px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 27.5px;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  transition: all 0.3s;
  transform: scale(1);
  border: 1px solid none;
  color: #fff;
  outline: none;
  letter-spacing: 2px; }
  .btn-black:hover {
    transition: 0.3s;
    background: #393834;
    color: #fff; }

.btn-yelow {
  background: #ffc80d;
  padding: 15px 30px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 27.5px;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  transition: all 0.3s;
  transform: scale(1);
  border: 1px solid none;
  color: #242321;
  outline: none;
  letter-spacing: 2px; }
  .btn-yelow:hover {
    transition: 0.3s;
    background: #ffbc0d;
    color: #242321; }

.btn-transparent {
  background: #fff;
  padding: 15px 30px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 27.5px;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  transition: all 0.3s;
  transform: scale(1);
  border: 1px solid #242321;
  color: #242321;
  outline: none;
  letter-spacing: 2px; }
  .btn-transparent:hover {
    transition: 0.3s;
    background: rgba(151, 151, 151, 0.21);
    color: #242321; }

.btn-lean_more {
  display: inline-block !important;
  font-size: 16px;
  color: #302d2d;
  font-weight: bold;
  position: relative;
  padding: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  z-index: 1; }
  .btn-lean_more-white a {
    color: #fff !important; }
  .btn-lean_more .main_sections_btn_icon {
    width: 56px;
    height: 56px;
    background-color: #ffc80d;
    position: absolute;
    z-index: -1;
    left: 0px;
    transform: translateY(-50%);
    top: 50%;
    border-radius: 56px; }

.btn-lean_more.is-focus-over .main_sections_btn_icon {
  animation-name: swipe_element;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; }

.btn-lean_more.is-focus-out .main_sections_btn_icon {
  animation-name: swipe_element_our;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; }

.btn-more_work {
  background-color: #ffc80d;
  font-size: 16px;
  font-weight: bold;
  line-height: 3;
  color: #242321;
  text-align: center;
  transition: all 0.3s;
  max-width: 300px;
  margin: 30px auto 20px;
  letter-spacing: 2px;
  padding: 0 30px; }
  .btn-more_work:hover {
    transition: 0.3s;
    background-color: #e1b009;
    letter-spacing: 1px; }

.btn_wrap {
  margin-top: 25px; }

.btn-submit {
  background-color: #ffc80d;
  color: #242321;
  font-weight: bold;
  border: none;
  padding: 15px 30px;
  border-radius: 27px; }

.btn-shifting {
  display: flex;
  align-items: center; }

.btn-advice {
  background: #2b2a27;
  padding: 15px 25px;
  color: #fff;
  font-weight: bold;
  border-radius: 27.5px;
  text-transform: uppercase; }

.btn-advertising {
  background-color: #ffc80d;
  padding: 20px 50px;
  font-weight: bold;
  border-radius: 27.5px;
  text-transform: uppercase;
  color: #242321;
  font-size: 18px; }
  .btn-advertising-black {
    background-color: #2b2a27;
    color: #fff;
    padding: 16px 40px;
    font-size: 16px; }

.btn-drag {
  display: flex;
  align-items: center;
  max-width: 220px;
  animation: drag_left 1s ease-in 0s infinite; }
  .btn-drag img {
    margin-right: 20px; }

.btn-black,
.btn-yelow {
  transition: all 0.3s;
  letter-spacing: 2px; }
  .btn-black:hover,
  .btn-yelow:hover {
    letter-spacing: 1px; }

@keyframes hover_left {
  0% {
    transform: translateX(0px); }
  50% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0px); } }

@keyframes swipe_element_our {
  0% {
    right: 0;
    left: initial;
    width: 100%; }
  30% {
    width: 100%; }
  100% {
    right: initial;
    left: 0;
    width: 56px; } }

@keyframes swipe_element {
  30% {
    width: 100%; }
  100% {
    right: 0;
    left: initial;
    width: 100%; } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.05); } }

@keyframes drag_left {
  0% {
    margin-right: 20px; }
  50% {
    margin-right: 35px; }
  100% {
    margin-right: 20px; } }

@media screen and (max-width: 992px) {
  .btn-advertising {
    font-size: 16px; }
  .section_box {
    width: 95%; } }

@media screen and (max-width: 680px) {
  .our_works_wrap {
    margin-top: 20px !important;
    grid-template-columns: 1fr !important;
    grid-gap: 20px !important; }
  .our_works__item_img {
    margin: 0 0 25px 0 !important; } }

@media screen and (max-width: 490px) {
  .btn-advertising {
    font-size: 14px;
    padding: 20px 20px; }
  .section_box {
    width: 95%; } }

.swipe {
  display: flex;
  align-items: center;
  position: relative;
  width: 240px; }
  .swipe-dot, .swipe-dot-1 {
    width: 30px;
    height: 30px;
    display: block; }
  .swipe-dot {
    position: absolute;
    left: 15px;
    top: 15px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid; }
  .swipe-liner {
    width: 150px;
    height: 1px;
    position: absolute;
    left: 35px; }
    .swipe-liner::after {
      content: '. . . . . . . . . . . . . . . . . . . .';
      position: absolute;
      top: -5px;
      width: 100%;
      line-height: 0.5px;
      height: 1px;
      letter-spacing: 3px;
      font-size: 20px; }
  .swipe-group {
    position: absolute;
    left: calc(100% - 30px);
    height: 40px;
    width: 40px; }
  .swipe-btn {
    width: 40px;
    transition: 0.3s;
    transform: scale(1);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute; }
  .swipe_text {
    position: absolute;
    opacity: 0.7;
    font-size: 14px;
    line-height: 1.71;
    color: #302d2d;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    transition: 0.3s;
    bottom: -25px; }
  .swipe_animation-click .swipe_text {
    transition: 0.3s;
    opacity: 0; }
  .swipe_animation-start .swipe-group {
    animation: swipe_start_btn 0.5s ease-out 0s forwards; }
  .swipe_animation-start .swipe-liner {
    transition: 0.5s; }
  .swipe_animation-final .swipe-group {
    animation: swipe_final_btn 0.5s ease-out 0s forwards; }
    .swipe_animation-final .swipe-group .swipe-btn {
      animation: swipe_final_btn_step2 0.5s ease-out 0.5s forwards; }
  .swipe_animation-final .swipe-liner {
    transition: 0.5s;
    width: 0;
    opacity: 0; }
  .swipe_animation-final .swipe_text {
    opacity: 0; }
  .swipe_animation-final .swipe-dot {
    animation: swipe_final_btn_step3 0.5s ease-out 0.5s forwards; }

@keyframes swipe_start_btn {
  from { }
  to {
    left: 190px; } }

@keyframes swipe_final_btn {
  from { }
  to {
    left: -4px; } }

@keyframes swipe_final_btn_step2 {
  0% { }
  50% {
    opacity: 1; }
  to {
    height: 0;
    width: 0;
    opacity: 0; } }

@keyframes swipe_final_btn_step3 {
  0% { }
  20% {
    opacity: 1; }
  100% {
    height: 0;
    width: 0;
    opacity: 0; } }

/*Toggle Specific styles*/
input[type='checkbox'].toggle {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 55px;
  height: 28px;
  background-color: red;
  position: relative; }
  input[type='checkbox'].toggle:hover:after {
    background-color: red; }
  input[type='checkbox'].toggle:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: #660000;
    top: 2px;
    left: 2px; }

.toggle_wrap {
  display: flex;
  color: #fff;
  align-items: center; }

.header__admin-exit {
  color: #fff; }
