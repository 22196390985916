.footer {
  background-color: #242321;
  padding: 30px 0;
  margin-top: 40px; }
  .footer_container {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px; }
  .footer_sections {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .footer__logo {
    width: 62px; }
  .footer_group {
    grid-template-columns: 1fr;
    display: grid;
    margin-bottom: 15px; }
    .footer_group__title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.33;
      color: #ffffff; }
    .footer_group__item {
      font-size: 14px;
      line-height: 1;
      color: #ffffff;
      margin: 8px 0;
      transition: 0.3s; }
      .footer_group__item:hover {
        transition: 0.3s;
        color: #ffc80d; }
  .footer_main {
    margin-top: 15px; }
    .footer_main-socail {
      display: flex;
      align-items: center; }
    .footer_main__item {
      font-family: "Lato", sans-serif;
      font-size: 14px;
      color: #ffffff;
      margin: 0px 5px;
      position: relative; }
      .footer_main__item:after {
        display: block;
        position: absolute;
        content: "";
        height: 2px;
        width: 0;
        background: #ffffff;
        transition: width 0.3s ease-in-out;
        right: 0;
        bottom: 0; }
      .footer_main__item:hover {
        color: #ffc80d; }
      .footer_main__item:first-child {
        margin-left: 0; }
      .footer_main__item:last-child {
        margin-right: 0; }
      .footer_main__item-social {
        display: flex;
        align-items: center;
        margin-right: 15px;
        transition: 0.3s;
        transform: scale(1); }
        .footer_main__item-social:hover {
          transition: 0.3s;
          transform: scale(1.1); }

.footer .main__foter {
  z-index: 1;
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 20px; }
  .footer .main__foter_social {
    margin: 0 40px; }
    .footer .main__foter_social-link {
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      margin: 0 20px;
      position: relative; }
      .footer .main__foter_social-link:after {
        display: block;
        position: absolute;
        content: "";
        height: 2px;
        width: 0;
        background: #fff;
        transition: width 0.3s ease-in-out;
        right: 0;
        bottom: 0; }
      .footer .main__foter_social-link:hover:after {
        width: 40%;
        background-color: #fff; }
  .footer .main__foter_lang {
    margin-left: 30px;
    font-size: 14px;
    display: flex;
    align-items: center; }
    .footer .main__foter_lang-link {
      position: relative;
      color: rgba(255, 255, 255, 0.5);
      transition: 0.3s; }
      .footer .main__foter_lang-link:hover {
        color: #fff;
        transition: 0.3s; }
      .footer .main__foter_lang-link-active {
        color: #fff;
        transition: 0.3s; }
        .footer .main__foter_lang-link-active:before {
          position: absolute;
          bottom: -3px;
          width: 100%;
          height: 2px;
          background-color: #fff;
          content: ""; }
    .footer .main__foter_lang-dot {
      width: 2px;
      height: 2px;
      background-color: #fff;
      margin: 5px; }

@media screen and (max-width: 768px) {
  .footer_main__item {
    margin: 0; }
    .footer_main__item-social {
      margin: 0 5px; }
  .footer_container {
    grid-template-columns: 1fr; }
  .footer_main-socail {
    margin: 0; } }
