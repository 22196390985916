.db_content-active {
  border: 1px solid #0063ea;
  position: relative; }

.edit_link {
  position: absolute;
  height: 15px;
  width: 15px;
  color: #fff;
  transition: .0s;
  padding: 5px 3px 5px 5px;
  background-color: #0063ea;
  z-index: 99999;
  top: 0;
  right: -20px;
  transform: translateY(-50%);
  top: 50%; }
  .edit_link:hover {
    transition: .3s;
    transform: scale(1.05) translateY(-50%); }
  .edit_link svg {
    display: block; }

.header__admin {
  background-color: #242321;
  display: flex;
  align-items: center; }
  .header__admin__coll_2 {
    width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .header__admin_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin: 10px auto; }

.toggle_btn {
  margin-left: 10px; }
