.brief_st {
  margin-bottom: 70px; }

.form_title {
  font-size: 70px;
  line-height: 85px;
  font-weight: 700;
  color: #242321; }
  @media screen and (max-width: 600px) {
    .form_title {
      font-size: 40px;
      line-height: 46px; } }

@media screen and (max-width: 768px) {
  .feedback_smal {
    margin-bottom: 40px; }
    .feedback_smal .container-coll-3_bottom {
      grid-gap: 0; } }

@media screen and (max-width: 600px) {
  .feedback_smal .container-coll-3_bottom {
    width: 100%; } }

@media screen and (max-width: 748px) {
  .form_col div:first-child {
    margin-top: 0; } }
