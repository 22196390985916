.fp-tableCell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative; }

.fp-right {
  margin: 0;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translate(0, -50px); }
  .fp-right-disble {
    right: -30px;
    transition: .4s; }
  .fp-right ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .fp-right ul li:first-child {
      margin: 15px 0;
      background: #fff;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      position: absolute;
      right: -12px;
      top: -40px; }
      .fp-right ul li:first-child a span {
        right: 0px !important; }
      .fp-right ul li:first-child a {
        height: 10px; }
      .fp-right ul li:first-child .fp-sr-only {
        opacity: 0; }
    .fp-right ul li .fp-tooltip {
      display: none; }
    .fp-right ul li a {
      display: flex;
      align-items: center;
      position: relative; }
      .fp-right ul li a span {
        color: #fff;
        font-size: 21px; }
        .fp-right ul li a span:first-child {
          width: 23px;
          height: 29px;
          text-align: center; }
        .fp-right ul li a span:last-child {
          width: 20px;
          height: 1px;
          background: #fff;
          position: absolute;
          right: -10px;
          top: 50%;
          transform: translate(100%, -50%); }
    .fp-right ul li .active {
      margin-left: -30px; }
      .fp-right ul li .active span:last-child {
        right: 20px;
        width: 50px; }

.main_bg {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
  background: #242321; }

.main_box {
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr minmax(45%, 50%);
  width: 90%;
  grid-column-gap: 50px;
  align-items: center; }

.main_sections {
  z-index: 1; }
  .main_sections:last-child {
    z-index: 0; }
  .main_sections_title {
    font-size: 60px;
    font-weight: bold;
    line-height: 1.56;
    color: #fff;
    user-select: none; }
  .main_sections_subtitle {
    font-size: 24px;
    line-height: 1.4;
    font-weight: normal;
    color: #fff;
    user-select: none;
    margin: 15px 0;
    max-width: 600px; }
  .main_sections_btn {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 20px 0;
    position: relative;
    padding: 20px; }
    .main_sections_btn_icon {
      width: 56px;
      height: 56px;
      background-color: #ffc80d;
      border-radius: 50%;
      position: absolute;
      z-index: -1;
      left: -5px; }
    .main_sections_btn a {
      text-transform: uppercase;
      user-select: none; }
    .main_sections_btn svg {
      margin-left: 15px; }
  .main_sections_img {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative; }
    .main_sections_img_bg {
      height: 100%;
      width: 100%;
      position: absolute;
      pointer-events: none; }
      .main_sections_img_bg img {
        width: 100% !important;
        height: auto  !important;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        pointer-events: none; }
    .main_sections_img img {
      width: 130%;
      pointer-events: none; }
  .main_sections_text {
    display: none; }
  .main_sections_svg_img {
    display: none;
    margin-top: -80px;
    position: absolute;
    right: 0; }

.main__scrol {
  z-index: 1;
  position: absolute;
  bottom: 90px;
  left: 25px;
  transform: rotate(-90deg);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 120px;
  height: 1rem;
  overflow: hidden;
  cursor: pointer; }
  .main__scrol-black {
    color: #242321; }
    .main__scrol-black .main__scrol_icon {
      background: #242321; }
  .main__scrol-white {
    color: #fff; }
    .main__scrol-white .main__scrol_icon {
      background: #fff; }
  .main__scrol_text {
    user-select: none; }
  .main__scrol_icon {
    height: 1px;
    width: 0;
    margin-right: 20px;
    animation: scrol_line 1.5s linear 0s infinite; }

.main__foter {
  z-index: 1;
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 20px; }
  .main__foter_social {
    margin: 0 40px; }
    .main__foter_social-link {
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      margin: 0 20px;
      position: relative; }
      .main__foter_social-link:after {
        display: block;
        position: absolute;
        content: "";
        height: 2px;
        width: 0;
        background: #fff;
        transition: width 0.3s ease-in-out;
        right: 0;
        bottom: 0; }
      .main__foter_social-link:hover:after {
        width: 40%;
        background-color: #fff; }
  .main__foter_lang {
    font-size: 14px;
    display: flex;
    align-items: center; }
    .main__foter_lang-link {
      position: relative;
      color: rgba(255, 255, 255, 0.5);
      transition: .3s; }
      .main__foter_lang-link:hover {
        color: #fff;
        transition: .3s; }
      .main__foter_lang-link-active {
        color: #fff;
        transition: .3s; }
        .main__foter_lang-link-active:before {
          position: absolute;
          bottom: -3px;
          width: 100%;
          height: 2px;
          background-color: #fff;
          content: ""; }
    .main__foter_lang-dot {
      width: 2px;
      height: 2px;
      background-color: #fff;
      margin: 5px; }

.bals_1 {
  display: block;
  width: 55px;
  height: 55px;
  position: absolute;
  top: 10%;
  left: 40%; }
  .bals_1 img {
    display: block;
    height: 100%;
    width: auto; }

.bals_2 {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 30%;
  left: 5%; }
  .bals_2 img {
    display: block;
    height: 100%;
    width: auto; }

.bals_3 {
  position: absolute;
  bottom: 15%;
  left: 20%;
  height: 40px;
  width: 40px; }
  .bals_3 img {
    display: block;
    height: 100%;
    width: auto; }

@keyframes move_eye {
  from {
    bottom: 90px; }
  to {
    bottom: 100px; }
  60% {
    bottom: 90px; } }

@media screen and (max-width: 1440px) {
  .main_sections_title {
    font-size: 50px; }
  .main_sections_img {
    height: 250px; }
    .main_sections_img img {
      height: 150%;
      width: auto; }
  .main_box {
    width: 90%; } }

@media screen and (max-width: 1130px) {
  .main_sections_title {
    font-size: 35px; }
  .main_sections_subtitle {
    font-size: 18px; }
  .main_sections_img {
    height: 200px; } }

@media screen and (max-width: 992px) {
  .fp-tableCell {
    height: 100% !important;
    display: block; }
  .section {
    position: relative; }
  .main {
    min-height: 100vh; }
    .main_box {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-evenly;
      margin: 0 auto;
      height: 100%; }
  .bals_1, .bals_2, .bals_3 {
    display: none; } }

@media screen and (max-width: 992px) {
  @keyframes move_eye {
    from {
      bottom: 90px; }
    to {
      bottom: 100px; }
    60% {
      bottom: 90px; } } }

@media screen and (max-width: 768px) {
  .main_box {
    width: 80%;
    margin-right: 5%; } }

@media screen and (max-width: 440px) {
  .main_sections_img {
    height: auto; }
    .main_sections_img img {
      display: none; }
  .main_sections_text {
    display: block;
    color: #ffc80d;
    text-align: left;
    text-transform: uppercase;
    margin: 15px 0; }
  .main_sections_svg_img {
    display: block; }
  .main_box {
    justify-content: center;
    align-items: baseline; }
  .main__foter {
    display: none; }
  .main__scrol {
    bottom: 65px;
    left: calc(50%);
    transform: rotate(-90deg) translateX(-50%);
    transform-origin: left; } }

@media screen and (max-width: 360px) {
  .main_sections_svg_img {
    display: block;
    right: -20px; } }

@media screen and (max-width: 992px) {
  #section_01 {
    height: 100vh; }
  header {
    top: -90px;
    animation: heder_botton .5s linear 6s  forwards; }
  .animatiop-minimal {
    animation: heder_botton .3s linear .3s  forwards !important; } }

@media screen and (max-width: 480px) {
  .main_sections_btn {
    padding: 20px 10px;
    font-size: 14px; } }

@keyframes scrol_line {
  from {
    width: 0px;
    transform: translateX(0px); }
  50% {
    width: 40px;
    transform: translateX(0px); }
  100% {
    transform: translateX(-40px);
    width: 40px; } }

@keyframes heder_botton {
  0% {
    top: -60px; }
  100% {
    top: 0; } }
