.prew__wrap {
  position: absolute;
  z-index: 1000;
  background: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; }
  .prew__wrap-active {
    animation: Stet_3 0.3s ease-in-out 3.4s forwards; }
    .prew__wrap-active .prew__box {
      background: #fff;
      animation: Stet_2 1.9s ease-in-out 1.25s forwards; }
      .prew__wrap-active .prew__box svg {
        animation: Stet_1 1.8s ease-in-out forwards; }
  .prew__wrap-visible {
    opacity: 1; }
  .prew__wrap-none {
    display: none; }

.prew__box {
  width: 210px;
  background: #fff;
  height: 50px;
  overflow: hidden;
  position: absolute; }
  .prew__box svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -77px); }

.header-animation {
  opacity: 0;
  animation: Stet_7 1s ease-in-out 5.2s forwards; }

.text_logo {
  position: absolute;
  top: 0;
  color: rgba(255, 255, 255, 0.1);
  font-size: 25vh;
  font-weight: 900;
  transform: rotate(-90deg) translateY(-50%);
  left: 66%;
  -webkit-font-smoothing: unset;
  text-transform: uppercase; }
  .text_logo-star {
    line-height: 0.9;
    transform: translate(100%, 0); }
  .text_logo-way {
    line-height: 0.9;
    transform: translate(-150%, 0px); }

.section-animation-min .main_box .main_sections {
  opacity: 0; }
  .section-animation-min .main_box .main_sections:first-child {
    animation: Stet_6-text 0.3s ease-in-out 0.2s forwards; }
  .section-animation-min .main_box .main_sections:last-child {
    animation: Stet_6-img 0.3s ease-in-out 0.2s forwards; }

.section-animation .main_bg img {
  opacity: 0;
  animation: Stet_5-img 0.4s ease-in-out 3.8s forwards; }

.section-animation .main_bg .text_logo-star {
  animation: Stet_4-text1 0.8s ease-in-out 3.4s forwards; }

.section-animation .main_bg .text_logo-way {
  animation: Stet_4-text2 0.8s ease-in-out 3.4s forwards; }

.section-animation .main_box .main_sections {
  opacity: 0; }
  .section-animation .main_box .main_sections:first-child {
    animation: Stet_6-text 1s ease-in-out 4.2s forwards; }
  .section-animation .main_box .main_sections:last-child {
    animation: Stet_6-img 0.7s ease-in-out 4s forwards; }

.section-animation .main__scrol,
.section-animation .main__foter {
  opacity: 0;
  animation: Stet_7 1s ease-in-out 5.2s forwards; }

.section-animation.bals_1 img,
.section-animation .bals_2 img,
.section-animation .bals_3 img {
  opacity: 0;
  transform: translateY(-40px);
  animation: Stet_6-bals 1s ease-in-out 4.8s forwards; }

@keyframes Stet_1 {
  0% { }
  40% {
    transform: translate(-50%, -50%); }
  80% { }
  100% {
    transform: translate(-50%, 70px); } }

@keyframes Stet_2 {
  0% {
    background: #242321;
    opacity: 0; }
  33% {
    width: 1px;
    opacity: 1;
    background: #242321;
    height: 50px; }
  66% {
    width: 1px;
    opacity: 1;
    background: #242321;
    height: 100vh; }
  100% {
    width: 100%;
    opacity: 1;
    background: #242321;
    height: 100vh; } }

@keyframes Stet_3 {
  0% { }
  100% {
    opacity: 0;
    z-index: -1000; } }

@keyframes Stet_4-text1 {
  from { }
  100% {
    transform: translate(-75%, 0px); } }

@keyframes Stet_4-text2 {
  from { }
  100% {
    transform: translate(5%, 0); } }

@keyframes Stet_5-img {
  0% { }
  100% {
    opacity: 1; } }

@keyframes Stet_6-text {
  0% { }
  100% {
    opacity: 1; } }

@keyframes Stet_6-img {
  0% { }
  100% {
    opacity: 1; } }

@keyframes Stet_6-bals {
  0% {
    opacity: 0; }
  10% {
    opacity: 0; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@keyframes Stet_7 {
  0% { }
  100% {
    opacity: 1; } }
