#c {
  width: 100%;
  height: 100%;
  display: block;
  outline: none; }

.sw_mobile_bg {
  display: none; }

.dg.a {
  margin-top: 60px; }

@media (max-width: 992px) {
  #c {
    display: none; }
  .sw_mobile_bg {
    display: block; } }
