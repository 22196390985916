@charset "UTF-8";
header {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0; }

.header__menu_item {
  -moz-user-select: none;
  /* Mozilla Firefox */
  -ms-user-select: none;
  /* Internet Explorer (не поддерживается) */
  -o-user-select: none;
  /* Opera Presto (не поддерживается) */
  -webkit-user-select: none;
  /* Google Chrome, Opera Next, Safari */ }

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 20px 5px; }
  .header-color-white {
    background-color: transparent;
    border: none; }
    .header-color-white .header__logo svg g {
      fill: #fff; }
    .header-color-white .header__menu_item {
      color: #fff; }
    .header-color-white .header_btn .hamburger-inner,
    .header-color-white .header_btn .hamburger-inner::before,
    .header-color-white .header_btn .hamburger-inner::after {
      background-color: #fff; }
  .header-white {
    z-index: 400;
    background-color: #fff; }
    .header-white .header_btn .hamburger-inner,
    .header-white .header_btn .hamburger-inner::before,
    .header-white .header_btn .hamburger-inner::after {
      background-color: #302d2d; }
  .header__menu_header {
    display: none; }
  .header__menu_footer {
    display: none; }
  .header__menu_item {
    margin-left: 15px;
    font-size: 18px;
    font-weight: 500;
    transition-delay: 0.3s;
    position: relative; }
    .header__menu_item:hover {
      transition: 0.3s;
      color: #ffc80d !important; }
    .header__menu_item-action:before {
      display: block;
      content: "";
      position: absolute;
      border-radius: 50%;
      left: calc((50%));
      transform: translate(-50%, -50%);
      bottom: -15px;
      width: 8px;
      height: 8px;
      background-color: #ffc80d !important; }
    .header__menu_item-action:hover {
      transition: 0.3s;
      color: #313638 !important; }
    .header__menu_item:after {
      display: block;
      position: absolute;
      content: "";
      height: 2px;
      width: 0;
      background: #ffc80d;
      transition: width 0.3s ease-in-out;
      right: 0;
      bottom: 0; }
    .header__menu_item svg {
      display: none; }
  .header__menu_svg {
    display: none; }
  .header__menu_responsive_footer {
    display: none; }
  .header__menu_box-white .header__menu_item {
    transition-delay: 0.3s;
    color: #fff; }
  .header_btn {
    display: none; }
  .header__logo {
    height: 38px; }
    .header__logo svg {
      width: auto; }
    .header__logo-black svg path {
      fill: #302d2d; }

@media screen and (max-width: 992px) {
  .header-color-white .header_btn .hamburger-inner,
  .header-color-white .header_btn .hamburger-inner::before,
  .header-color-white .header_btn .hamburger-inner::after {
    background-color: #fff; }
  .header-white {
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9; }
    .header-white .header_btn .hamburger-inner,
    .header-white .header_btn .hamburger-inner::before,
    .header-white .header_btn .hamburger-inner::after {
      background-color: #302d2d; }
    .header-white .header__logo svg g {
      fill: #302d2d; }
    .header-white .header__menu_header .header__logo svg g {
      fill: #fff; }
  .header_btn {
    display: block; }
    .header_btn .hamburger-inner,
    .header_btn .hamburger-inner::before,
    .header_btn .hamburger-inner::after {
      background-color: #fff; }
  .header__menu {
    background: #242321;
    position: absolute;
    right: -120%;
    transition: 0.3s;
    top: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100; }
    .header__menu_btn .hamburger-inner,
    .header__menu_btn .hamburger-inner::before,
    .header__menu_btn .hamburger-inner::after {
      background-color: #fff !important; }
    .header__menu-active {
      right: 0%;
      transition: 0.3s; }
    .header__menu_social_link {
      color: #fff;
      font-size: 15px;
      margin-right: 15px; }
      .header__menu_social_link:last-child {
        margin-right: 0; }
    .header__menu_lang {
      display: flex;
      align-items: center; }
      .header__menu_lang-dot {
        width: 2px;
        height: 2px;
        background-color: #fff;
        margin: 5px; }
      .header__menu_lang_link {
        color: rgba(255, 255, 255, 0.3);
        position: relative; }
        .header__menu_lang_link-active {
          color: #fff; }
          .header__menu_lang_link-active:before {
            position: absolute;
            bottom: -3px;
            width: 100%;
            height: 2px;
            background-color: #fff;
            content: ""; }
    .header__menu_item {
      font-size: 34px !important;
      color: #fff !important;
      margin: 10px 0;
      display: flex;
      align-items: center;
      line-height: 1; }
      .header__menu_item svg {
        margin-right: 10px;
        display: block; }
    .header__menu_svg {
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      pointer-events: none; }
    .header__menu_responsive {
      width: 90%;
      max-height: 80vh;
      overflow-y: auto;
      margin: 0 auto; }
      .header__menu_responsive_footer {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        margin: 20px 0; }
        .header__menu_responsive_footer a {
          color: #fff;
          padding: 3px 0; }
    .header__menu_box {
      display: flex;
      flex-direction: column; }
    .header__menu_header {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .header__menu_footer {
      color: #fff;
      display: flex;
      justify-content: space-between;
      padding: 10px; } }

@media screen and (max-width: 992px) {
  header {
    background-color: #fff;
    top: 0; }
  .header-color-white .header_btn .hamburger-inner,
  .header-color-white .header_btn .hamburger-inner::before,
  .header-color-white .header_btn .hamburger-inner::after {
    background-color: #fff; }
  .header-white {
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9; }
    .header-white .header__menu_item {
      color: #302d2d; }
    .header-white .header_btn .hamburger-inner,
    .header-white .header_btn .hamburger-inner::before,
    .header-white .header_btn .hamburger-inner::after {
      background-color: #302d2d; }
    .header-white .header__logo svg g {
      fill: #302d2d; }
  .header .hamburger-inner,
  .header .hamburger-inner::before,
  .header .hamburger-inner::after {
    background-color: #302d2d; } }

@media screen and (max-width: 768px) {
  .header-color-white .header_btn .hamburger-inner,
  .header-color-white .header_btn .hamburger-inner::before,
  .header-color-white .header_btn .hamburger-inner::after {
    background-color: #fff; }
  .header-white {
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9; }
    .header-white .header_btn .hamburger-inner,
    .header-white .header_btn .hamburger-inner::before,
    .header-white .header_btn .hamburger-inner::after {
      background-color: #302d2d; }
    .header-white .header__logo svg g {
      fill: #302d2d; }
    .header-white .header__menu_header .header__logo svg g {
      fill: #fff; }
  .header_btn {
    display: block; }
    .header_btn .hamburger-inner,
    .header_btn .hamburger-inner::before,
    .header_btn .hamburger-inner::after {
      background-color: #242321; }
  .header__menu {
    background: #242321;
    position: absolute;
    right: -120%;
    transition: 0.3s;
    top: 0;
    width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100; }
    .header__menu_btn .hamburger-inner,
    .header__menu_btn .hamburger-inner::before,
    .header__menu_btn .hamburger-inner::after {
      background-color: #fff !important; }
    .header__menu-active {
      right: 0%;
      transition: 0.3s; }
    .header__menu_social_link {
      color: #fff;
      font-size: 15px;
      margin-right: 15px; }
      .header__menu_social_link:last-child {
        margin-right: 0; }
    .header__menu_lang {
      display: flex;
      align-items: center; }
      .header__menu_lang-dot {
        width: 2px;
        height: 2px;
        background-color: #fff;
        margin: 5px; }
      .header__menu_lang_link {
        color: rgba(255, 255, 255, 0.3);
        position: relative; }
        .header__menu_lang_link-active {
          color: #fff; }
          .header__menu_lang_link-active:before {
            position: absolute;
            bottom: -3px;
            width: 100%;
            height: 2px;
            background-color: #fff;
            content: ""; }
    .header__menu_item {
      font-size: 34px !important;
      color: #fff !important;
      margin: 10px 0;
      display: flex;
      align-items: center;
      line-height: 1; }
      .header__menu_item svg {
        margin-right: 10px;
        display: block; }
    .header__menu_svg {
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0; }
    .header__menu_responsive {
      width: 90%;
      margin: 0 auto; }
      .header__menu_responsive_footer {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        margin: 20px 0; }
        .header__menu_responsive_footer a {
          color: #fff;
          padding: 3px 0; }
    .header__menu_box {
      display: flex;
      flex-direction: column; }
    .header__menu_header {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .header__menu_footer {
      color: #fff;
      display: flex;
      justify-content: space-between;
      padding: 10px; } }

@media screen and (max-width: 390px) {
  .header__menu {
    width: 100%; } }
